.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    margin: auto;
    height: 100vh;
}

.loadingHolder{
    height: 7rem;
    width: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    border-radius: 1rem;
}