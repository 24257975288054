.addAddress{
    background-color: #202021;
        height: 40vh;
        max-width: 1200px;
        margin: auto;
        border-radius: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 2rem 1.5rem;
}

.addressFormContainer{
    display: flex;
    justify-content: space-between;
    margin-top: .7rem;
}

.selectContainer, .addressContainer, .remarkContainer{
    display: flex;
    flex-direction: column;
    
}

.selectContainer{
    width: 15%;
}

.addressContainer{
    width: 50%;
}

.remarkContainer{
    width: 30%;
}

.addressInput{
    background-color: transparent;
    border: 1px solid #575757;
    height: 2.7rem;
    padding-left: .5rem;
    color: #fff;
    outline: none;
    border-radius: .5rem;
}

.addressLabel{
    font-size: .7rem;
    color: #d0d3d3;
    margin-bottom: .2rem;
}