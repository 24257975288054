.sliderClass {
    background-color: #18181A;
}

.sliderSection {
    padding-top: 3rem;
    background-color: #18181A;
}

.sliderParent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider {
    width: 90%;
    margin: auto;
    
}

.slick-slide>div {
    margin: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}

.slick-prev:before,
.slick-next:before {
    color: #fff;
}

.sliderClass__Text {
    font-family: Euclid-Circular, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.3rem;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.025em;
    font-feature-settings: 'liga' off;
    margin-bottom: 1rem;
    color: #fff;

}
@media screen and (max-width: 1000px) {
    .sliderSection {
       padding-top: 1rem;
 
    }
}


.caroselBtnContainer {
    margin: 3rem 0;
}

@media screen and (max-width: 480px) {
    .slider {
        width: 85%;

    }

}

@media screen and (max-width: 400px) {
    .slider {
        width: 80%;

    }

}