.heroSection{
    background: url('../../Assets/banner.png');
    height: 80vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
}
.heroSectionBackdrop{
    background-color: rgb(24, 24, 26, .6);
    height: 80vh;
    width: 100vw;
}

.heroBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50vh;
    max-width: 1200px;
    
    /* width: 100%; */
    margin: auto;
    margin-top: 2rem;
}

.heroTextContainer{
    width: 45%;
}

.heroTextHeader{
    font-size: 2rem;
    color: #fff;
}

.heroTextDesc{
    color: #b3afb2;
    font-weight: 400;
}

.heroNav{
    display: none;
}

@media screen and (max-width: 1220px) {
    .heroBox{
        width: 90%;
        margin: auto;
    }

}

@media screen and (max-width: 1100px) {
    .heroTextContainer{
        width: 60%;
    }

}

@media screen and (max-width: 1024px) {
    .heroSection {
            display: none;
        }
    .heroNav{
        display: block;
        background-color: rgb(24, 24, 26);
    }
}

