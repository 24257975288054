.WithdrawalRecords{
    background-color: #202021;
        height: 85vh;
        max-width: 1200px;
        margin: auto;
        border-radius: 1.5rem;
        width: 100%;
        margin-top: 2rem;
        padding: 2rem 1.5rem;
        display: flex;
        flex-direction: column;

}

.withdrawalsRecordsTextBox{
    border-bottom: 1px solid #505050;
    padding-bottom: 1rem;
}

.withdrawalsRecordsText{
    font-size: 1rem;
    color: #fff;
}

.withdrawRecords{
    height: 100%;
    margin-top: .3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emptyWithdraw{
    
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userWithdrawTable{
    height: fit-content;
}

@media screen and (max-width: 1210px) {
    .WithdrawalRecords{
        width: 90%;
        margin: auto;
        margin-top: 2rem;
    }
}