.icoCardBody{
    width: 100%;
    height: 9rem;
    border-radius: 1rem;
    padding: 1rem;
    cursor: pointer;
    border: 1px solid #43444B;
    display: flex;
    justify-content: space-between;

}

.adminIcoCardProgressLength{
    display: none;
}

.cardDescItems{
    display: flex;
}

.icoImg{
    margin-right: 1rem;
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    /* align-self: center; */
}

.icoNameBox{
    display: flex;
    margin-bottom: .3rem;
}

.icoName{
    margin-right: 1rem;
    color: #FFA92D;
    align-self: center;
    font-weight: 600;
}

.icoCardAttr{
    
    background-color: #372F23;
    border-radius: .6rem;
    width: fit-content;
    height: fit-content;
    padding: .5rem;
}

.icoCardAttrOption{
    font-size: .7rem;
    color: #FFA92D;
}

.icoSubs{
    display: flex;
}

.icoSubName{
    color: rgb(156, 156, 156);
    margin-right: 1rem;
    font-size: .9rem;
    font-weight: 400;
}

.coinAmount{
    color: #fff;
    font-size: .9rem;
}

.cryptoCoinAmount{
    color: #CE4444;
    font-size: .9rem;
}

.cardPercentage{
    width: 12%;
    align-self: center;
}

@media screen and (max-width:600px){
    .icoImg {
            align-self: center;
            width: 5rem;
            height: 5rem;
            
        }
    .cardDescItems{
        padding-top: 1rem;
    }
}

@media screen and (max-width:500px) {
    .icoImg {
        width: 4rem;
        height: 4rem;
    }
}

@media screen and (max-width: 400px) {
.icoCardBody {
        width: 100%;
        height: 9rem;
        border-radius: 1rem;
        padding: .5rem;
        margin: auto;
        flex-direction: column;

    }
    .icoImg {
        width: 3.5rem;
        height: 3.5rem;
        margin-right: 1rem;
    }
    
    .cardPercentage {
        display: none;
    }
    .adminIcoCardProgressLength{
        display: flex;
    }
    .progressbarLength span {
        font-size: .6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
}

@media screen and (max-width:319px){
    .icoImg {
            margin-right: .5rem;
        }
}