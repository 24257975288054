.mobileNavBarHolder{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #272727;
    display: none;
    height: 4rem;
    
    z-index: 50;
}

.mobileNavBar{
    width: 90%;
    height: 100%;
    align-items: center;
    margin: auto;
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
 
}


.mobileLinkActive{
    background-color: #FFA92D;
}



.mobileNavBox{
    display:flex;
    flex-direction: column;
}

.navIcon{
    align-self: center;
    text-decoration: none;
}

.mobileNavLink{
    text-decoration: none;
    font-size: .8rem;
    
}

.mobileLink{
    text-decoration: none;
    color: #FFA92D;
}

.mobileLinkNotActive{
    text-decoration: none;
    color: #616060;
}

@media screen and (max-width: 1000px) {
    .mobileNavBarHolder{
        
        display: flex;
        align-items: center;
    }   
}