.withDrawalTableContainer{
    margin-top: 0;
    /* height: 30rem; */
    height: fit-content
}

.withdrawTable{
    height: 100vh;
}

.viewBtn{
    background-color:#FFA92D;
    outline: none;
    border: none;
    border-radius: .3rem;
    width: 100%;
    cursor: pointer;
    height: 2rem;
    display: block;
    margin: auto;
}

.adminSingleWithdraw{
    background-color: #000;
    height: fit-content;
    

}

.SingleWithdrawal{
    /* height: 100vh; */
    /* background-color: red; */
    background-color: #000;
}