.addressRecords{
        background-color: #202021;
        height: 80vh;
        max-width: 1200px;
        margin: auto;
        border-radius: 1.5rem;
        width: 100%;
        margin-top: 2rem;
        padding: 2rem 1.5rem;
        display: flex;
        flex-direction: column;
}