.ExchangePageParent{
    width: 100%;
    
    background-color: #151616;
}

.exchangePageContainer{
    max-width: 1200px;
    margin: auto;
}

.exchangeContainer div{
    height: 90vh;
}

.tradingview-widget-copyright{
    height: 0 !important;
}

@media screen and (max-width: 1220px) {
    .exchangePageContainer{
        
        margin: auto;
        width: 95%;
        
    }
}