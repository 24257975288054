.setFundPassword{
    height: 100vh;
}

@media screen and (max-width: 1210px){
    .fundPasswordbox {
        width: 90%;
        margin: auto;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 850px) {
    .bindteleBox {
        width: 60%;
        margin: auto;
    }
}

@media screen and (max-width: 600px) {
    .bindteleBox {
        width: 70%;
        margin: auto;
    }
}

@media screen and (max-width: 450px) {
    .bindteleBox {
        width: 80%;
        margin: auto;
    }
}