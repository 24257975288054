.linearGradientBody{
    background: linear-gradient(to right, #FFB331, #EB5067);
    display: flex;
    height: 10rem;
    flex-direction: column;
}

.withdrawContainer{
    max-width: 1200px;
    margin: auto;
    display: flex;
    
    width: 100%;
    margin-bottom: 1rem;
}

.withdraw{
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    align-self: center;
}

.arrowBox{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .7rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: .5rem;
    margin-right: 1rem;
    cursor: pointer;
    
}

.arrowBox:hover{
    background-color: rgba(255, 255, 255, .3);
}

.backArw{
    align-self: center;
    font-size: 1.5rem;
    color: #fff;
}

.withdrawals{
    
    background-color: #18181A;
    width: 100%;
    padding: 2rem 0;
}

.editWithdrawal{
    margin-top: 2rem;
    width: 40%;
}


@media screen and (max-width: 1210px) {
    .withdrawContainer{
        width: 95%;
        margin: auto
    }
    

    .withdraw{
        font-size: .9rem;
    }

}

@media screen and (max-width: 900px) {
    .editWithdrawal {
            width: 60%;
        }
        .withdrawContainer{
            padding-top: 2rem;
            margin-bottom:2rem ;
    }


}

@media screen and (max-width: 800px) {
    .editWithdrawal {
        width: 70%;
    }

}

@media screen and (max-width: 700px) {
    .editWithdrawal {
        width: 80%;
    }

}

@media screen and (max-width: 600px) {
    .editWithdrawal {
        width: 100%;
    }

}