.idPhotosBox{
    margin-top: 2rem;
    padding-bottom: 1rem;
}

.imageBox{
    height: 13rem;
    border-radius: 1rem;
    width: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #43444B;
    
    position: relative;
}
.plusSign{
    color: #A7AAAD;
    font-size: 2rem;
    font-weight: 400;
}

.imageBoxParent{
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 55%;
}

.fileInput{
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    content-visibility:hidden ;
}

.uploadText{
    color: #A7AAAD;
    font-size: .7rem;
    margin-top: .4rem;
    text-align: center;
}

.idPhotosBox {
    height: fit-content;
}

@media screen and (max-width: 1200px){
    .imageBoxParent{
        
        width: 60%;
    }
}

@media screen and (max-width: 1100px) {
    .imageBoxParent {

        width: 70%;
    }
    .idPhotosBox{
        margin-top: 2rem;
    }
}
@media screen and (max-width: 950px) {
    .imageBoxParent {

        width: 80%;
    }
}

@media screen and (max-width: 820px) {
    .imageBoxParent {

        width: 90%;
    }
    
}

@media screen and (max-width: 730px) {
    
    .imageBox{
        height: 15rem;
        width: 15rem;
    }
}

@media screen and (max-width: 610px) {

    .imageBox {
        height: 13rem;
        width: 13rem;
    }
}

@media screen and (max-width: 520px) {

    .imageBoxParent{
        flex-direction: column;
        height: fit-content;
        padding-bottom: 2rem;
        padding-top: 1rem;
    }

    .imageBoxParent div{
        margin-bottom: 1rem;
    }
    .imageBox {
        height: 8rem;
        width: 100%;
        
    }
}