.icoDetailsBox{
    width: 100%;
    background-color: #18181A;
    height: 105vh;
    padding-top: 2rem;
}

.icoDetailsHodingBox{
    max-width: 1200px;
    height: 95vh;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 2rem;
    background-color: #202021;
    border-radius: 1rem;
    padding: 1.5rem;
}

.icoDetailsHodingBoxLeft{
    width: 65%;
    height: 100%;
    padding-bottom: 1rem;
}

.icoDetailsHodingBoxRight{
    width: 32%;
}

.icoLeftSub{
    display: flex;
    height: fit-content;
    border-bottom: 1px solid #43444B;
    padding-bottom: 1rem;

}

.icoLeftImg{
    height: 5rem;
    width: 5rem;
}

.icoDetailsCoinName{
    align-self: center;
    color: #fff;
}

.activityInfoBox{
    padding-bottom: 1rem;
    border-bottom: 1px solid #43444B;
}

.progressBox{
    border-bottom: 1px solid #43444B;
}

.totalSupplyBox{
    display: flex;
    justify-content: space-between;
    margin-bottom: .7rem;

}

.progress{
    color: #9ea5a4;
    font-size: .7rem;
}

.progressbarLength{
    width: 100%;
    height: .4rem;
    background-color: #4A4C51;
    border-radius: 1rem;
    margin-bottom: .7rem;
}

.progressBar{
    height: 100%;
    background-color: #FFB331;
    border-radius: 1rem;
    width: 85%;
}

.bolder{
    color: #adadad;
    font-size: .9rem;
}

.lighter{
    color: #fff;
    font-size: .9rem;
}

.otherCardDetails{
    margin-top: 1rem;
    border-bottom: 1px solid #43444B;
    padding-bottom: 1rem;
}

.attendBtn{
    width: 100%;
    height: 3rem;
    color: #fff;
    border-radius: .5rem;
    outline: none;
    border: none;
    margin-top: 1rem;
}

.attendBtn:hover{
    cursor:no-drop;
}

.EditIco{
    margin-top: 1rem;
    width: 100%;
}

.editIcoText{
    color: #FFB331;
    margin-bottom: 1rem;
}

.EditIcoInputsContainer{
    width: 70%;
    height: 20rem;
    margin: auto;
}
.editIcoInput{
    width: 100%;
    height: 2.5rem;
    margin-bottom: 1rem;
    border-radius: .5rem;
    outline: none;
    padding-left: .5rem;
    border: none;
}
.updateIcoBtn{
    background-color:#FFB331;
    cursor: pointer;
}

.updateIcoBtn:hover {
    cursor: pointer;
}

.success{
    margin-top: 1rem;
    color: rgb(35, 172, 35);
}


@media screen and (max-width: 1220px){
    .icoDetailsHodingBox{
        margin: auto;
        width: 90%;
    }
}

@media screen and (max-width: 900px){
    .EditIcoInputsContainer {
            width: 80%;
    
    }
}

@media screen and (max-width: 700px) {
    .icoDetailsHodingBox {
        flex-direction: column;
        height: fit-content;
        
        
    }
    .icoDetailsBox{
        
        height: fit-content;
        padding-bottom: 2rem;
    }
    
    .icoDetailsHodingBoxLeft{
        width: 100%;
        height: 100%;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
    }
    .icoLeftSub2{
        margin-bottom: 0;
    }
    .EditIcoInputsContainer {
        width: 100%;
    
    }
    
.icoDetailsHodingBoxRight{
    width: 100%;
    margin-top: 3rem;
}

.activityInfoBox2{
    margin-top: 0;
}


}

@media screen and (max-width: 450px ){
    .icoDetailsHodingBox{
        padding: 1rem;
    }
}