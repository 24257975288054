.loginContainer{
    background-color: #18181A;
    width: 100vw;
    height: 100vh;
    padding: 2rem;
}

.logoHolder{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem
}

.logoHolder h1{
    color: #fff;
}

.backArrowBox{
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;
}

.backArrowBox:hover{
    background-color: #30281C;
}

.backArrow{
    color: #fff;
    font-size: 1.5rem;
}

.backArrow:hover {
    color: #FFB331;
    cursor: pointer;
}

.formContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginForm{
    width: 27rem;
    /* height: 28rem; */
    padding: 2rem;
    border-radius: 1rem;
    background-color: #202021;
}

.inputContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.loginLabel{
    color: #fff;
    margin-bottom: .5rem;
}

.loginInput{
    background-color: #1E1F20;
    border-radius: .5rem;
    padding: .6rem ;
    border: 1px solid #535353;
    outline: none;
    color: #fff;
}

.loginInput:hover{
    border: 1px solid var(--primaryColor);
}

.forgotPwd{
    color: var(--primaryColor);
    text-decoration: none;
    font-size: .8rem;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.signInBtn, .CreateAcctBtn{
    width: 100%;
    border-radius: .5rem;
    padding: .5rem;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: #FFB331;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1.5rem;

}

.signUp{
    color: #fff;
}

.signInBtn:hover{
    background-color: #D2A14E;
    
}

.CreateAcctBtn{
    background-color: transparent;
    color: var(--primaryColor);
    margin-bottom: 0;
}

.serviceBox{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.onlineService{
    font-size: .8rem;
    color: #bdbdbd;
    text-decoration: none;
    cursor: pointer;
    margin: auto;
    
}

.speaker{
    margin-right: 1.5rem;

}

.onlineService:hover{
    color: var(--primaryColor);
}

.errMsg{
    font-size: .6rem;
    color: red;
    margin-top: .1rem;
}

.resetBtn{
    margin-top: 1rem;
}


@media screen and (max-width: 500px) {
    .backArrowBox {
        justify-content: flex-start;
    }
    .loginForm{
        width: 95%;
    }

}


@media screen and (max-width: 350px) {
    .loginContainer {
        padding: 1.5rem;
    }
    .loginForm{
    padding: 1rem;
    }

}