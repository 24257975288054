.navholder{
    height: fit-content;
    width: 100%;
    background-color: #18181A;
}

.userDetailsBox{
    background: linear-gradient(to right, #6366F1, #2563EB) ;
    height: 10rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.userDetailsHolder{
    max-width: 1200px;
    margin: auto;
    width: 100%;
    display: flex;
    
}

.userProfileImage{
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    margin-right: 2rem;
    border-radius: 100%;
}

.userDetailsTextBox{
    align-self: center;
}
.userDetailsEmail{
    font-weight: 600;
    color: #fff;
    margin-bottom: .3rem;
}

.userDetailsID{
    font-size: .7rem;
    font-weight: 500;
    color: rgb(212, 212, 212);
}

.userDetails{
    background-color: #18181A;
    width: 100%;
    padding-top: 1.5rem;
}

.userProfileDetailsBox{
    max-width: 1200px;
    margin: auto;
    width: 100%;
}

.verifiedBox{
    padding: 1.5rem;
    height: 10rem;
    width: 100%;
    background-color: #202021;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
}

.securityBox{
    padding: 1.5rem;
    height: 15rem;
    width: 100%;
    background-color: #202021;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
}

.verifedText{
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
}

.verifiedBoxDesc{
    font-size: .7rem;
    margin: .7rem 0;
    color: rgb(212, 212, 212);
}

.verifiedBtn{
    width: 9rem;
    
}

.securityBoxHolder{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(66, 66, 66);
        padding: .4rem 0 1rem;

        margin-bottom: 1rem;

}

.securityBoxDetails{
    display: flex;
}

.securityLvl{
    display: flex;
    justify-content: space-between;
    height: 1.5rem;
    align-items: center;
}

.securityLvlBox{
    height: .5rem;
    width: 4rem;
    margin-left: .5rem;
    border-radius: 1rem;
    background-color: #fff;
}

.activeBox{
    background-color: #34f33e;
}

.securityLvlEmail{
    font-size: .85rem;
    color: rgb(212, 212, 212);
}

.securityLevelText{
    color: #fff;
}

.profileIcons{
    font-size: 1.5rem;
    margin-right: 2rem;
}
.shield{
    color: #feb032;
}

.phone{
    color: #3A64ED;
}

.mail{
    color: #F87171;
}

.bindTel{
    text-decoration: none;
    color: #feb032;
    font-size: .8rem;
    cursor: pointer;
}

.logoutBtn{
    width: 100%;
    background-color: #ef4444;
    outline: none;
    border: none;
    color: #fff;
    border-radius: .7rem;
    cursor: pointer;
    margin: 1.5rem 0;
    font-size: 1.1rem;
    height: 3.3rem;
}

.logoutBtn:hover{
    background-color: #c43b3b;
}

.lastOne{
    border: none;
}

@media screen and (max-width: 1250px){
    .userDetailsHolder{
        width: 95%;
        margin: auto
    }
    .userProfileDetailsBox{
        margin: auto;
        width: 95%;
    }
}

@media screen and (max-width: 1000px){
    .verifiedBox{
        padding-bottom: 1.5rem;
        height: fit-content;
    }
    .verifiedImage{
        width: 30%        
    }
    .userDetailsHolder{
        width: 90%;
        margin: auto
    }
    .userProfileDetailsBox{
        margin: auto;
        width: 90%;
    }

    .userProfile{
        padding-bottom: 3rem;
    }
}

@media screen and (max-width: 750px) {

    .verifiedImage {
        width: 40%
    }
}

@media screen and (max-width: 600px) {

    .verifiedImage {
        display: none;
    }
}

@media screen and (max-width: 500px){
    .userProfileImage{
        width: 3rem;
        height: 3rem;
        align-self: center;
    }
    .securityLevelText{
        font-size: .8rem;
        align-self: center;
    }

    .profileIcons{
        font-size: 1.5rem;
        margin-right: 1rem;
    }
}

@media screen and (max-width: 500px){
    .securityBoxHolderSec{
        flex-direction: column;
    }
    .securityLvlSec{
        width: 60%
    }
}

@media screen and (max-width: 400px) {


    .securityLvlSec {
        width: 80%
    }
    .securityLvlEmail{
        font-size: .7rem;
    }
}