.emptyComponent{
    display: flex;
    flex-direction: column;
}

.emptyText{
    font-size: .8rem;
    color: #bebebe;
    margin-top: .4rem;
}

.emptyBoxHolder{
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}