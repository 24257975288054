.adminNav{
    width: 50%;
    justify-content: space-between;

}

.adminNav ul{
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    width: 70%;
    
}