.DepositRightHeader{
    display: flex;
}

.usdtDeposits{
    height: 90vh !important;
}

.DepositRightHeaderWarning{
    color: #cfcfcf;
    margin-left: 1rem;
    font-size: .7rem;
    font-weight: 400;
    align-self: center;
}

.depositDetailsBox{
    height: fit-content !important;
}



.DepositQrCodeBox{
    width: 100%;
    border: 1px solid #383838;
    margin-top: 1rem;
    height: 100%;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.qrInnerBox{
    display: flex;
    justify-content: center;
    align-items: center;
}

.qrCode{
    color: #fff;
}

.copyBtnParent{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.copyBtn{
    background-color: #feb032;
        border: none;
        border-radius: .5rem;
        width: 4.5rem;
        cursor: pointer;
        color: #fff;
        height: 2.5rem;
}

.copyBtn:hover {
    background-color: #D2952D;
}

.depositSectionBtns{
    outline: none;
    border: none;
    width: 7rem;
    height: 2.5rem;
    color: #fff;
    border-radius: .2rem;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    cursor: pointer;
}

.depositSectionBtns:hover{
    color: #000;
    background-color: #FFB331;
}



@media screen and (max-width: 1200px){
    .withdrawalRight{
        height: fit-content;
    }
    .DepositQrCodeBox{
        padding: 1rem;
    }
    
}

@media screen and (max-width: 960px) {
    .qrCode{
        font-size: .9rem;
    }

}

@media screen and (max-width: 780px) {
    .DepositRightHeader {
        justify-content: space-between;
    }
    
        .qrCode {
            font-size: .8rem;
        }

}

@media screen and (max-width: 450px) {
    .DepositRightHeader {
        flex-direction: column;
    }

    .DepositRightHeaderWarning{
        align-self: flex-start;
        margin-left: 0;
        margin-top: .5rem;
    }

        .qrCode {
            word-break: break-all;
        }
}