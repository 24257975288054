.signUpBoxParent{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.registerContainer{
    height: fit-content;
    height: 125vh;
}

.signUpBox{
    width: 25rem;
    background-color: #161618;
    border-radius: .8rem;
    border: 1px solid #666666;
    padding: 2rem;
}

.signUpBox h2{
    color: #fff;
    font-size: 1.3rem;
    width: 500;
}

.signUpOptionsContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 1rem;
    border: 1px solid #535353;
    margin-top: 1rem;
}

.signUpOptionsLeft, .signUpOptionsRight{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    height: 2.5rem;
    width: 49%;
    cursor: pointer;
}

.haveAnAcctBox{
    display: flex;
    align-items: center;
    justify-content: center;
}

.haveAnAcct{
    color: var(--primaryColor);
    font-size: .8rem;
    
    text-decoration: none;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .signUpBox {
        width: 95%;
        padding: 1rem;
    }
    .registerContainer{
        padding: 1rem;
    }
}

@media screen and (max-width: 350px){
    /* .signUpBox{
        padding: 1rem;
    } */
}