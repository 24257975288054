.adminParent{
    width: 100vw;
    margin: auto;
    height: fit-content;
    padding-bottom:  2rem;
    background-color: #000;
}

.userListContainer{
    max-width: 1200px;
    margin: auto;
}

.adminContainer{
    width: 100%;
    height: 100vh;
    display: flex;
}

.adminLinksSection{
    width: 20%;
    height: 100vh;
    background-color: #FFA92D;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 2rem ;
    
}

.adminBodySection{
    width: 80%;
    height: 100%;
    padding: 2rem;
    
}

@media screen and (max-width: 1210px){
    .userListContainer {
            width: 95%;
            padding-bottom: 3rem;
        }
}