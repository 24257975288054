:root{
  --primaryColor: #FBC900;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px
}

html{
  width: 100vw;
}

body{
  overflow-x: hidden;
  
}

body {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}