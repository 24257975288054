.aboutParent{
    width: 100%;
    background-color: #18181A;
    
}

.about{
    height: fit-content;
    max-width: 1200px;
    margin: auto;
    display: flex;
    padding-bottom: 8rem;
    
}

.leftSection{
    border-right: 1px solid#a3a3a3;
    padding-right: 2rem;
}

.rightSection{
    padding-left: 2rem;
}
#about, #privacy, #disclaimer, #policy{
    color: #fff;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.aboutDesc{
    color: #a3a3a3;
    line-height: 2.2rem;
    font-size: .8rem;
}

.linkBox{
    cursor: pointer;
    font-size: .8rem;
    margin-bottom: 1rem;
    padding: .5rem;
    border-radius: .5rem;
    color: #fff;
}

@media screen and (max-width:1210px)  {
    .about{
 
        width:90%;
        margin: auto;
        
    }   
}

@media screen and (max-width:450px) {
    .about {
        flex-direction: column;

    }
        .rightSection{
        padding-left: 0;
    }
}