.deleteModal{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 3  ;
    background-color: rgba(0, 0, 0, .8);
    color: #000;
    align-items: center ;
}

.deleteCard{
    width: 40%;
    height: 30%;
    background-color: #fff;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deleteQuestion{
    
    font-size: 1.3rem;
    text-align: center;
}

.deleteOptions{
    display: flex;
    margin: auto;
    margin-top: 2rem;
    justify-content: space-between;
    width: 50%;
}

.deleteYes{
    background-color: red;
    width: fit-content;
    border-radius: .5rem;
    cursor: pointer;
    padding: .5rem 3rem;
    color: #fff;
    outline: none;
    border: none;
}

.cancelDeleteText{
    cursor: pointer;
    align-self: center;
}

@media screen and (max-width: 900px){
 .deleteCard{
    width: 50%;
 }
 .deleteQuestion{
    font-size: 1.1rem;
 }
}

@media screen and (max-width: 800px) {


    .deleteQuestion {
        font-size: 1rem;
    }
}

@media screen and (max-width: 700px) {

    .deleteCard {
            width: 70%;
        }

    .deleteQuestion {
        font-size: 1rem;
    }
}

@media screen and (max-width: 500px) {


    .deleteQuestion {
        font-size: .9rem;
    }
    .deleteOptions{
        width: 70%;
    }

    .deleteYes{
        padding: .5rem 2rem;
    }
}

@media screen and (max-width: 450px) {

    .deleteOptions {
        width: 80%;
    }
}