.identityAuth{
    flex-direction: column;
}

.activityInfoBox{
    border-bottom: 1px solid #43444B;
    margin-bottom: 1rem;
    padding-top: 0;
    width: 100%;
    padding-left: 1rem;
    align-self: flex-start;
}

.authBtnHolder{

    margin-top: 2rem;
    width: 15%;
    margin: 2rem auto 0;
}



@media screen and (max-width: 1210px){
    .identityAuth {
            width: 90%;
            
            margin: auto;
            
        }
}

@media screen and (max-width:900px) {
    .authBtnHolder{
        width: 30%;
    }
}

@media screen and (max-width: 800px) {
    .identityAuthInputBox {
        width: 80%;
    }
    
}

@media screen and (max-width:450px) {
    .authBtnHolder {
        width: 50%;
    }
}

