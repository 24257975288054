.icoPage{
    height: 120vh;
    background-color: #1E1F20;
    width: 100vw;
    padding-bottom: 50rem;
    /* background-color: red; */
}



.icoBody{
    background-color: #1E1F20;
    width: 100%;
    /* height: 100vh; */
    padding: 5rem auto;
    
}

.icoHoldingBox, .adminIco {
    height: 0;
}

.icoBodyParent{
    max-width: 1200px;
    margin: auto;
}

.icoHolder{
    height: fit-content;
    width: 100%;
    padding-top: 2rem;
    
}

.icoHeaders{
    height: 2rem;
    display: flex;
    border-bottom: 1px solid #43444B;
    justify-content: space-between;
    padding-bottom: 3rem;
    /* background-color: red; */
}

.icoLeftOptions{
    display: flex;
    width: 20%;
    justify-content: space-between;
}

.icoOption{
    color: #d4d4d4;
}

.icoOptionBox{
    cursor: pointer;
    height: fit-content;
    width: fit-content;
    padding: .6rem .4rem .4rem;
    border-radius: 1rem;
}

.icoOptionBox:hover{
    background-color: #FFB331;
    color: #fff;

}

.transactionOrder{
    text-decoration: none;
    font-size: .8rem;
    color: #b4b4b4;
}

.transactionOrderIcon{
    color: #fff;
    font-size: 1rem;
    margin-right: .5rem;
}

.icoCardHolder{
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    height: fit-content;
}

.adminIco, .adminIcoParent{
    height: 110vh;
    
    padding-bottom: 3rem;
}

.adminIcoParent{
    
    padding-bottom: 5rem;
}

@media screen and (max-width: 1210px) {
    .icoBodyParent{
        width: 90%;
    }

}

@media screen and (max-width: 925px) {
    .icoCardHolder{
        grid-template-columns: repeat(1, 1fr);
    }
    

}