.tableParent{
    overflow-y: scroll;
    margin-top: 1rem;
    color: #fff;
}

table::-webkit-scrollbar, .userListTable::-webkit-scrollbar {
    display: none;
}

.userListTable{
    width: 100%;
    margin-top: 2rem;
    border: 1px solid #Fff;
    font-family: arial, sans-serif;
    height: fit-content;
    border-collapse: collapse;
    
}

td,th {
    cursor: pointer;
        text-align: left;
        padding: 8px;
        border: 1px solid #FFA92D;
    }
th {
    border: 1px solid #000;
    color: #000;
    cursor: text;
    background-color: #FFA92D;
}


@media screen and (max-width: 1000px){
    .userListTable{
        
        margin-top: 0;
        
        
    }
}