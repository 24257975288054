.offlineParent{
    display: flex;
    width: 100%;
    border: 1px solid #2D2E31;
    height: 65vh;
    
}

.offlineLeft{
    width: 60%;
    border-right: 1px solid #2D2E31;
    height: 100%;
}

.offlineRight{
    width: 40%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.delegateContainer{
    padding-left: 2rem ;
    
    height: 15%;
    display: flex;
    align-items: center;
    border: 1px solid #2D2E31;
    border-right: none;
    border-top: none;
}

.delegateOptions{
    height: 13%;
    border: 1px solid #2D2E31;
    border-right: none;
    border-top: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.delegateOptions p{
    font-size: .8rem;
    color: #63707C;
    padding: 0 1rem;
}

.offlineViewLogin{
    background-color: #FFB331;
    color: #fff;
    align-self: center;
}

.offlineViewLogin:hover{
    background-color: #D0932B;
}

.offlineLoginContainer{
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.offlineLoginContainer p{
    font-size: .9rem;
    color: #63707C;
    margin-bottom: .5rem;
}

.offlineLoginParent{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 72%;
    
}

.buySellOptionContainer{
    width: 100%;
    background-color: #43444B;
    height: 2.8rem;
    border-radius: .7rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.buySellBtn{
    width: 49%;
    height: 100%;
    border-radius: inherit;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #fff;
    font-weight: 400;
}

.offlineRightDescBox{
    display: flex;
    align-items: center;
    border: 1px solid #2D2E31;
    height: 3rem;
    border-radius: .8rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

.offlineRightDesc{
    color: #BFBFCC;
}

.offlineInputContainer{
    display: flex;
    align-items: center;
    border: 1px solid #2D2E31;
    height: 3rem;
    border-radius: .8rem;
    padding: .5rem 1rem;
    margin-bottom: 1.5rem;
}


.offlineInputField{
    height: 90%;
    outline: none;
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: .9rem;
    width: 90%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}

.offlineInputContainer span{
    font-size: .8rem;
    color: #BFBFCC;
}

.percentageOptions{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.percentageOption{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2D2E31;
    width: 19%;
    height: 2.5rem;
    border-radius: .5rem;
    font-size: .9rem;
    color: #BFBFCC;
    cursor:not-allowed;
}

.availBalText{
    font-size: .8rem;
    color: #BFBFCC;
}

.availBal{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.availBalFigure{
    color: #FFB331;
    font-size: .9rem;
}

.availBalCurrency{
    font-size: .8rem;
    color: #BFBFCC;
}

.offlineLoginBigBtn{
    width: 100%;
    height: 3rem;
    outline: none;
    border: none;
    border-radius: .8rem;
    background: linear-gradient(to right, #FFB331, #F84273);
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
}


@media screen and (max-width: 900px){
    .delegateOptions p{
        font-size: .7rem;
        color: #63707C;
        padding: 0 1rem;
        display: none;
    }
         

}


@media screen and (max-width: 830px){
    .offlineParent{
    display: flex;
    width: 100%;
    flex-direction:column;
    height: fit-content;
    padding-bottom: 4rem
    
}
    .offlineLeft{
        width: 100%;
        height: 30vh

        
    }

    .offlineRight{
        width: 100%;
        
    }
    .offlineRightDesc{
        font-size: .8rem;
    }

    .delegateContainer{
        height: 35%;
    }

    .offlineLoginParent{
        height: 50%;
    }


}