
.coinBodyParent{
    width: 100%;
    background-color: #18181A;
    padding-bottom: 2rem;
}

.coinBody{
    max-width: 1200px;
    width: 100%;
    background-color: #18181A;
    margin: auto;
    padding-top: 2rem;
}

.marketTrendText{
    color: #fff;
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    
}

.coinTableContainer{
    background-color: #202021;
    height: fit-content;
    border-radius: 1rem;
    padding: 1.5rem;
    width: 100%;
}

.coinTable:nth-child(even){
    background-color: #2B2B2C;
}

.coinRow{
    display: flex;
    justify-content: space-between;
    height: 4rem;
    padding: 0 1rem;
    align-items: center;
}

.coinRowHeader{
    color: #a19f9f;
    font-size: .8rem;
}

.coinContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.coinImage {
    width: 2rem;
}

.coinBtn {
    padding: .5rem;
    outline: none;
    border: none;
    background: linear-gradient(to right, rgb(255, 179, 49), #C64E58);
    cursor: pointer;
    border-radius: .4rem;
    color: #fff;
}

.marketHeading{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    
    margin-bottom: 2rem;
}

.hotContainer{
    background-color: #FFB331;
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: 1.5rem;
}

.searchContainer{
    background-color: #2D2E32;
    display: flex;
    width: 25%;
    padding: .8rem .5rem;
    border: 1px solid #686868;
    align-self: auto;
    border-radius: .5rem;
}

.searchIcon{
    color: #afadad;
    align-self: center;
}
.searchInput{
    background-color: transparent;
    outline: none;
    border: none;
    margin-left: .5rem;
    color: #cecece;
    width: 100%;
    font-size: .9rem;
    align-self: center;
}

.searchInput::placeholder{
    color: #cecece;
}

.coinName, .coinVolume{
    font-size: .9rem;
    color: #afadad;
}

.coinVolume{
    color: #cecece;
}

.marketTrendsSearch {
    max-width: 1200px;
    margin: auto;
}


@media screen and (max-width: 1220px){
   .marketTrendsSearch {
        width: 100%;
        margin: auto;
    } 
    .coinBody{
        margin: auto;
        width: 90%;
    }
}

@media screen and (max-width: 1020px){
    .coinBodyParent{
        display: none;
    }

    .marketTrendText{
        display: none;
    }
    
}