
@media screen and (max-width: 950px){
    .usdtDeposits{
    
    height: 100vh !important
}

}

@media screen and (max-width: 950px) {
    .usdtDeposits {
        
        height: fit-content !important
    }

}