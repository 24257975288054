.createFinanceContainer{
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 1rem 0;
}

.createFinanceBtn, .createUserFinance{
    background-color:rgb(255, 179, 49);
    border: none;
    outline: none;
    color: #fff;
    padding: .5rem;
    border-radius: .7rem;
    cursor: pointer;
}

.createFinanceBtn:hover, .createUserFinance:hover{
    background-color: rgb(235, 165, 44);
}

.createUserFinance{
    width: 100%;
}

.financeBodyParent{
    width: 100%;
    background-color: #000;
    height: 100vh;
    
}

.financeBody{
    max-width: 1200px;
    background-color: #000;
    margin: auto;
    width: 100%;
    height: 100vh;
    
}

@media screen and (max-width: 1210px) {
    .createFinanceContainer{
        width: 90%;
        margin: auto;
        
    }

}