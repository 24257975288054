

.financeHolder{
    max-width: 1200px;
    margin: auto;
    width: 100%;
    height: 100vh;
    background-color: #18181A;
}

.emptyBox{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
}



.financeParent, .singleFinanceParent{
    width: 100%;
    background-color: #18181A;
    /* height: 100vh */
}

.createFinanceBody{
    height: 100vh;
    align-items: flex-start;
}

.totalFinance{
    margin-top: 2rem;
    color: #fff;
}

@media screen and (max-width: 1210px){
    .financeHolder{
        
        margin: auto;
        width: 90%;
        
    }
}

@media screen and (max-width: 1000px) {
    .financeHolder {
        margin-top: 2rem;
    }
}