.AppDescContainer{
    
    background-color: #18181A;
        width: 100%;
        padding: 2rem 0;
}

.AppDesc{
    /* height: 100vh; */
    /* background-color: red; */
    /* width: 100%; */
    max-width: 1200px;
    
        background-color: #18181A;
        width: 100%;
        margin: auto;
}

.AppDescSections{
    display: flex;
    justify-content: space-between;
}

.AppDescSectionsLeft{
    width: 55%;
    align-self: center
}

.AppDescSectionsRight{
    width: 40%;
    align-self: center
}

.appDescTextHolder{
    margin-bottom: 3rem;
}

.AppDescSectionsHeader{
    color: #fff;
    margin-bottom: .5rem;
}

.AppDescSectionsDesc{
    color: #bdbbbb;
    font-size: .9rem;
}

.AppDescImage{
    width: 100%;
}

@media screen and (max-width: 1220px) {
    .AppDesc {
            width: 90%;
            margin: auto;
        }
}

@media screen and (max-width: 1024px){
    .AppDescContainer{
        display: none;
    }
}