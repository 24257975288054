.telephoneBox{
    max-width: 1200px;
    margin: auto;
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #202021;
    border-radius: 1rem;
}

.bindteleBox{
    width: 40%;
}

.activityInfo{
    margin: 1rem 0;
    color: #fff;
}

.fundPasswordbox{
    height: 50vh;
}