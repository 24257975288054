.dummyBox{
    height: 3rem;
    width: 100%;
    background-color: #1D1E1F;
}

.labOrderData{
    background-color: #121212;
    width: 100%;
    height: 100vh;
}

.labOrderHolder{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}