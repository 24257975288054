footer{
    width: 100%;
    background-color: #2D2E32;
}

.footer{
    max-width: 1200px;
    padding-top: 2rem;
        background-color: #2D2E32;
        width: 100%;
        margin: auto;
}

.footerBox1{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footerBrand{
    align-self: center;
}

.copyRightText{
    font-size: .8rem;
    color: #7a8185;
    margin-bottom: .5rem;
}

.aboutUsBox{
    border-top: .2px solid #797b7c;
    padding: 1rem 0;
    margin-top: 1rem;
}

.footerListParent{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.footerList{
    list-style: none;
}

.footerLink{
    color: #bdbbbb;
    text-decoration: none;
    font-size: .8rem;
}

@media screen and (max-width: 1300px){
    .footer{
        max-width: 1100px
    }
}

@media screen and (max-width: 1100px){
    .footer{
        max-width: 900px
    }
}

@media screen and (max-width: 1024px) {
    .footer {
        display: none;
    }
}

@media screen and (max-width: 900px){
    .footer{
        max-width: 700px
    }
}

@media screen and (max-width: 700px){
    .footer{
        max-width: 500px
    }
}

@media screen and (max-width: 500px){
    .footer{
        max-width: 300px
    }
}