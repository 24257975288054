.DesktopHeader{
    width: 100vw;
    height: 10vh;
    background-color: rgb(24, 24, 26, .1);
    
}

nav{
    display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1300px;
        margin: auto;  
        
        /*// when you want to make sure the max-width dosent exceed its limit. u add the max-width and margin :auto prop */
        /* padding: 0 1rem; */
}

.navParent{
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    margin-left: 2rem;
}

.left{
    display: flex;
    align-items: center;
    width: 35%;
}

.brand{
    text-decoration: none;
    color: #fff;
}

.logoImg{
    height: 1.5rem;
}

.navLink, .navLinkActive{
    font-size: .95rem;
    cursor: pointer;
    text-decoration: none;
    color: #a8a8a8;
}

.navLinkActive{
    color: #fff
}

.loginBtn{
    background-color: #fff;
    color: rgb(255,179, 49);
    cursor: pointer;
    border-radius: .4rem;
    font-size: .9rem;
    border: none;
    padding: .5rem 1rem;

}

.signUpBtn {
    color: #fff;
    background-color: rgb(255, 179, 49);
    cursor: pointer;
    border-radius: .4rem;
    font-size: .9rem;
    border: none;
    margin-left: 1.5rem;
    padding: .5rem 1rem;
    
}

.navParentDiv, .userParentDiv{
    display: flex;
    align-items: center;
    height: 9vh;
        
}

.userParentDiv{
    justify-content: space-between;
    cursor: pointer;
    position: relative;
}

.userImage{
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1rem
}

.userEmail{
    font-size: .8rem;
    color: #fff;
    
}

.downArrow{
    margin-left: 1rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400;
}

.subMenuBoxContainer{
    position: absolute;
    top: 3rem;
    right: .5rem;
    z-index: 5;
}

.subMenuBox{
    height: 35rem;
    border-radius: 1rem;
    display: none;
    
    flex-direction: column;
    transition: height 2s linear;
    width: 15rem;
}

.subMenuTop{
    width: 100%;
    height: 30%;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6556D4;
}

.subMenuBottom{
    width: 100%;
    height: 50%;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    background-color: #323233;
    display: flex;
    flex-direction: column;
    padding: .7rem;
}

.userParentDiv:hover .subMenuBox{
    display: flex;
}

.subMenuImage{
    width: 4rem;
    border-radius: 100%;
    text-align: center;
    height: 4rem;
}

.subMenuTopComponents{

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subImageBox{
    width: 100%;
    margin-bottom:.7rem ;
    display: flex;
    justify-content: center;
    align-items: center;
}



.subMenuBottomNavItems{
    height: 75%;
}

.subMenuBottomLinksBox{
    padding: .8rem;
}

.subMenuBottomLinksBox:hover{
    background-color: #565565;
    border-radius: .7rem;
}

.subMenuLinks{
    color: #b6b6b6;
    font-size: .9rem;
}

.subMenuLogoutBox{
    border-top: 1px solid #707070;
    display: flex;
    align-items: center;
    padding: 1rem;
    height: 30%;
}

.subMenuLogoutBtn{
    color: #ef4444;
    font-weight: 400;
}

.cont{
    display: flex;
    align-items: center;
}

.logOutBtn{
    height: 50%;
    align-self: auto;
}


.normalNavContainer{
    display: none;
}

.mobileOption{
    color: #fff;
    font-size: 1.5rem;
}

.normalNavRegBtn{
    margin-left: 0;
    margin-top: 2rem;
}


@media screen and (max-width: 1330px) {
    .adminNav {
        width: 0%;
    }
    nav{
    display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1200px;
        margin: auto;  
        
        /*// when you want to make sure the max-width dosent exceed its limit. u add the max-width and margin :auto prop */
        /* padding: 0 1rem; */
    }
}

@media screen and (max-width: 1200px) {
    nav {
        width: 95%;
        margin: auto;
    }
}

@media screen and (max-width: 1000px){
    nav{
        display: none;
    }
    .normalNavContainer{
        display: flex;
        height: fit-content;
        padding-top: 1rem;
        justify-content: space-between;
        width: 95%;
        margin: auto;
    }

    .normalMenuNotOpen{
        display: none;
        transform: none;
    }
    
    .normalMenu{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 10vh;
        left: 0;
        background-color: rgb(24, 24, 26);
        height: 90vh;
        z-index: 54;
        align-items: center;
        width: 100%;
        /* transition: all .5s ease ; */
        
        /* justify-content: space-between; */
    }

    .normalMenu ul{
        margin-top: 3rem;
    }

    .normalMenu li{
        list-style-type: none;
        margin-bottom: 2rem;
        text-align: center;
    }
}