.mobileMarketTrends{
    width: 100%;
    background-color: #18181A;
    display: none;
}

.mobileMarketTrendHolder{
    max-width: 1200px;
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mobileMarketTrendCard{
    display: flex;
    height: 3.5rem;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #3b3a3a;
    border-bottom: 1px solid #3b3a3a;
}

.mobileCardLeftSection{
    display: flex;
}

.mobileCardImage{
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    margin-right: 1rem;
    align-self: center;
}

.mobileCoinName{

    color: #fff;
    font-size: .9rem;
}

.mobileUSDT{
    color: #aaa9a9;
}

.mobileCoinVol{
    color: #aaa9a9;
    font-size: .7rem;
}

.mobileCoinPrice{
    color: #fff;
    font-weight: 600;
}

.mobileCoinPercentage{
    color: rgb(219, 18, 18);
    font-size: .7rem;
    align-self: end;
}

.mobileCardRightSection div{
    display: flex;
    flex-direction: column;
}

.mobileSearchContainer{
    max-width: 1200px;
    margin: auto;
}

@media screen and (max-width: 1020px){
    .mobileMarketTrends{
        
        display: block;
        padding-bottom: 7rem;
        
    }
    .mobileMarketTrendHolder{
        margin: auto;
        width: 90%;
        
    }

    .mobileSearchContainer{
    width: 90%;
    margin: auto;
    padding-top: 1rem;
}
    .mobileSearchInputContainer{
        width: 80%;    
    }
}