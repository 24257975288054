.withdrawalDetails{
    background-color: #202021;
    height: 75vh;
    max-width: 1200px;
    margin: auto;
    border-radius: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2rem 1.5rem;
}

.withdrawalLeft, .withdrawalRight{
    width: 48%;   
}

.withdrawalRight{
    display: flex;
    flex-direction: column;
}

.withdrawalLeftText{
    color: #fff;
}

.currencySelect{
    width: 40%;
    margin-top: .7rem;
    height: 2.7rem;
    border-radius: .5rem;
    padding: .5rem ;
    outline: none;
    background: transparent;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
}

.withdrawalOptions{
    color: gold
}

.moneyBox{
    height: fit-content;
    background-color: #2B2B2C;
    padding: 1rem;
    width: 100%;
    margin-top: 1rem;
    border-radius: 1rem;
}

.balanceBox{
    display: flex;
    justify-content: space-between;
    font-size: .9rem;
    margin-bottom: 1rem;
}

.availableBalance{
    color: #929394;
}

.ethBal{
    color: #d0d3d3;
}

.spin{
    cursor: pointer;
    color: #929394;
}

.withdrawalDescBox{
    margin-top: 1.5rem;
}

.withdrawalDesc{
    color: #b8b8b8;
    line-height: 1.3rem;
    margin-bottom: .3rem;
    font-size: .8rem;
}

.withdrawalAddressTextBox{
    display: flex;
    justify-content: space-between;
}

.userAddressLink{
    color: #FEB032;
    font-size: .9rem;
    text-decoration: none;
    align-self: center;
}

.addressinputBox{
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.withdrawalAddressInput{
    width: 100%;
    padding: .6rem;
    height: 3rem;
    background-color: #1E1F20;
        border-radius: .5rem;
        border: 1px solid #535353;
        outline: none;
        color: #fff;
}
.btcBal{
    color: #FEB032;
}

.withdrawalSubmitBtnContainer{
    margin-top: 2.5rem;
    display: flex;
    justify-content: flex-end;
}

.withdrawalSubmitBtn{
    background-color: #feb032;
    border: none;
    border-radius: .5rem;
    width: 6rem;
    cursor: pointer;
    height: 2.5rem;
}

.withdrawalSubmitBtn:hover{
    background-color: #D2952D;
}


@media screen and (max-width: 1210px){
    .withdrawalDetails{
        width: 90%;
        margin: auto;
    }
}

@media screen and (max-width: 800px) {
    .withdrawalDetails {
        flex-direction: column;
        height: fit-content;
        padding: 1rem;
    }
    .withdrawalLeft, .withdrawalRight{
        width: 100%;   
    }
}

@media screen and (max-width: 600px) {
    .currencySelect{
        width: 70%
    }
    .availableBalance{
        font-size: .9rem;
    }
    .ethBal, .withdrawalLeftText, .userAddressLink{
    font-size: .9rem;}

    .btcBal{
        font-size: .8rem;
    }
}